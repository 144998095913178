@import url(http://fonts.googleapis.com/css?family=Roboto:400,300,700|Open+Sans:700|Anton);
html {
  font-size: 30% !important;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 62.5% !important;
  }
}

.btn:hover {
  color: #fff;
}

.about p, .news p {
  font-size: 16px;
  font-size: 1.6rem;
}

.btn-gray {
  background: #2f2f2f;
  background-color: #2f2f2f;
  color: #fff;
  font-weight: 700;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-gray:hover {
  background-color: #101010;
  color: #dea428;
}

.btn-yellow {
  background: #dea428;
  padding: 10px 15px;
  color: #fff;
}

.btn-yellow:hover {
  background: #c08c1d;
  transition: all 0.3s ease;
}

.btn-yellow:active {
  background: #755512;
}

.btn-yellow.btn-xl {
  font-size: 25px;
  font-size: 2.5rem;
}

.btn-green {
  background: #92ae01;
  padding: 10px 15px;
  color: #fff;
}

.btn-green:hover {
  background: #708501;
  transition: all 0.3s ease;
}

.btn-green:active {
  background: #282f00;
}

.btn-green.btn-xl {
  font-size: 25px;
  font-size: 2.5rem;
}

@media only screen and (min-width: 1200px) {
  .Ama {
    margin-top: 201px;
    margin-bottom: 133px;
  }
}

#counters {
  z-index: 10;
  position: relative;
}

.bg-yellow {
  background-color: #dea428;
  color: #fff;
  padding: 7px;
  line-height: 1.5;
}

.bg-green {
  background-color: #92ae01;
  color: #fff;
  padding: 7px;
  line-height: 1.5;
}

.bg-blue {
  background-color: #269ec6;
  color: #fff;
  padding: 7px;
  line-height: 1.5;
}

.green {
  color: #92ae01;
}

.t1 {
  font-family: anton;
  font-size: 44px;
  font-size: 4.4rem;
}

.t1.w {
  color: #fff;
}

.t3 {
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 15px;
}

.t3.w {
  color: #fff;
}

.t4 {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 15px;
}

.t4.w {
  color: #fff;
}

.soc {
  text-align: left;
  margin-top: 15px;
}

.soc ul {
  margin-left: 0px !important;
}

.slicknav_menu:before {
  float: left;
  height: 40px;
  background: url(../../images/logo.png) no-repeat;
  width: 110px;
  margin-left: 7px;
  margin-top: 0px;
  background-size: cover;
}

.headerLine {
  background: url("../../images/dotbg.png");
  background-repeat: repeat;
  height: 400px;
}

.headerLine #menuF, .headerLine .container {
  position: relative;
  z-index: 2;
}

.headerLine #menuF {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 0px;
}

.headerLine #menuF.fixed {
  position: fixed !important;
  z-index: 999 !important;
}

@media only screen and (min-width: 992px) {
  .headerLine #menuF {
    height: auto;
  }
}

.headerLine #portada-content h1 {
  font-size: 48px;
  font-size: 4.8rem;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .headerLine #portada-content h1 {
    font-size: 45px;
    font-size: 4.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .headerLine #portada-content h1 {
    font-size: 48px;
    font-size: 4.8rem;
  }
}

.headerLine #portada-content .col-img {
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .headerLine #portada-content .col-img {
    text-align: left;
  }
}

.headerLine #portada-content .col-img .img-addon {
  background-color: rgba(255, 255, 255, 0.56);
  width: 80%;
  margin-bottom: 20px;
  border-radius: 4px;
}

@media only screen and (min-width: 992px) {
  .headerLine #portada-content .col-img .img-addon {
    margin-bottom: 0px;
    width: 100%;
    background: none;
  }
}

.headerLine #img-addon {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 80%;
}

@media only screen and (min-width: 768px) {
  .headerLine #img-addon {
    margin: initial;
    width: initial;
    left: initial;
    width: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .headerLine #img-addon {
    width: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  .headerLine #img-addon {
    right: 5%;
  }
}

.headerLine.is-loading #bg {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  .headerLine {
    height: 500px;
  }
}

@media only screen and (min-width: 992px) {
  .headerLine {
    height: 550px;
  }
}

.pic2Ab, .pic1Ab {
  width: 180px;
  padding-bottom: 40px;
}

.line2 {
  background: url("../../images/dotyellow.png");
  background-repeat: repeat;
}

.line4 {
  background: url("../../images/dotyellow.png");
  background-repeat: repeat;
}

.line4:before {
  z-index: 10;
}

.line5 {
  background: url("../../images/dotyellow.png");
  background-repeat: repeat;
}

.line7 {
  padding-top: 150px;
  background: url("../../images/dotbg.png");
  background-repeat: repeat;
  /* &:before{
	border-top: 100px solid lighten($gray, 40%);	
    border-right: 2000px solid transparent;
	} */
}

.line7 .ftext h3, .line7 .ftext p {
  color: #fff;
}

.partners {
  background-color: #959595;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  padding-top: 180px;
  min-height: 500px;
}

.partners:before {
  top: 0;
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background: url(../../images/sliderL.png) center center no-repeat; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 10px; */
  border-top: 194px solid white;
  border-right: 3000px solid transparent;
}

.line-last {
  background: url("../../images/dotbg.png");
  background-repeat: repeat;
  height: 100px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.line-last:before {
  content: "";
  position: absolute;
  z-index: 1;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-bottom: 150px solid #101010;
  border-left: 3000px solid transparent;
}

.lineBlack {
  background: #101010;
}

.lineBlack:before {
  content: "";
  width: 0px;
  height: 0px;
}

.lineBlack .downLine {
  margin-top: 20px;
  margin-bottom: 20px;
}

#news p {
  text-align: justify;
}

#news ul {
  text-align: left;
  padding-left: 20px;
}

#news ul li {
  color: #2f2f2f;
  font-size: 16px;
  font-size: 1.6rem;
  list-style: initial;
  text-decoration: initial;
  display: list-item;
}

#form-contacto input[type='text'], #form-contacto input[type='email'], #form-contacto textarea {
  margin-bottom: 10px;
}

.info-contacto {
  padding-left: 0px;
}

.info-contacto li {
  font-size: 15px;
  color: #fff;
  list-style: none;
}

.info-contacto li i {
  color: #dea428;
  margin-right: 5px;
}

.info-contacto li a {
  color: #fff;
}

.info-contacto li a i {
  color: #dea428;
}

#bg {
  -moz-transition: pacity 2s ease-in-out;
  -o-transition: pacity 2s ease-in-out;
  -webkit-transition: pacity 2s ease-in-out;
  transition: pacity 2s ease-in-out;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

#bg div {
  -moz-transition: opacity 3s ease, visibility 3s;
  -o-transition: opacity 3s ease, visibility 3s;
  -webkit-transition: opacity 3s ease, visibility 3s;
  transition: opacity 3s ease, visibility 3s;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 150%;
}

#bg div.visible {
  -webkit-animation: bg 45s linear infinite;
  -moz-animation: bg 45s linear infinite;
  -ms-animation: bg 45s linear infinite;
  -o-animation: bg 45s linear infinite;
  animation: bg 45s linear infinite;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

@media only screen and (min-width: 600px) {
  #bg div.visible {
    -webkit-animation: bg 18s linear infinite;
    -moz-animation: bg 18s linear infinite;
    -ms-animation: bg 18s linear infinite;
    -o-animation: bg 18s linear infinite;
    animation: bg 18s linear infinite;
  }
}

@media only screen and (min-width: 992px) {
  #bg div.visible {
    -webkit-animation: bg 29.25s linear infinite;
    -moz-animation: bg 29.25s linear infinite;
    -ms-animation: bg 29.25s linear infinite;
    -o-animation: bg 29.25s linear infinite;
    animation: bg 29.25s linear infinite;
  }
}

#bg div.top {
  z-index: 2;
}

#bg div:only-child {
  -webkit-animation-direction: alternate !important;
  -moz-animation-direction: alternate !important;
  -ms-animation-direction: alternate !important;
  -o-animation-direction: alternate !important;
  animation-direction: alternate !important;
}

@-webkit-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-25%);
    -o-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
}

@-moz-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-25%);
    -o-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
}

@-o-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-25%);
    -o-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
}

@keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-25%);
    -o-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
}
